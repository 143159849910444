import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SnapchatCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Parse the access token from the URL fragment
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(1));
    const accessToken = params.get("access_token");
    const expiresIn = params.get("expires_in");

    if (accessToken) {
      // Save the token (e.g., localStorage or state management)
      localStorage.setItem("snapchat_access_token", accessToken);
      localStorage.setItem(
        "snapchat_token_expiry",
        Date.now() + expiresIn * 1000
      );

      // Redirect to the homepage or another route
      navigate("/");
    }
  }, [navigate]);

  return <div>Processing Snapchat Login...</div>;
};

export default SnapchatCallback;
