import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";
import { QRCodeSVG } from "qrcode.react";
import "./Styles.css";

import Popover from "react-bootstrap/Popover";
import PopoverHeader from "react-bootstrap/PopoverHeader";
import PopoverBody from "react-bootstrap/PopoverBody";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import baseUrl from "../../utils/baseUrl";

export default function StudentQuickAddModal(props) {
  const user = useSelector(selectUser);
  const [url, setUrl] = useState("pongolearning.com");
  const [copied, setCopied] = useState(false); // State to manage copy action
  const [countdown, setCountdown] = useState("10:00"); // Initial countdown value

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/generate_key/${user.id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        // setUrl(`pongolearning.com/add/${responseData.key}`);
        setUrl(`${responseData.key}`);

        // Calculate countdown time
        const timestamp = responseData.timestamp; // Assuming responseData.timestamp is in milliseconds
        const endTime = timestamp + 60 * 60 * 1000; // 60 minutes from the timestamp
        updateCountdown(endTime);

        // Update countdown every second
        const intervalId = setInterval(() => {
          updateCountdown(endTime);
        }, 1000);

        return () => clearInterval(intervalId); // Cleanup interval
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Function to update countdown timer
  const updateCountdown = (endTime) => {
    const now = new Date().getTime();
    let distance = endTime - now;

    // Check if the countdown has finished
    if (distance <= 0) {
      setCountdown("00:00");
      // Optionally stop further updates
      return; // Stop further countdown updates
    }

    // Calculate minutes and seconds from remaining milliseconds
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Format minutes and seconds with leading zeros
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    setCountdown(`${formattedMinutes}:${formattedSeconds}`);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url); // Copy URL to clipboard
    setCopied(true); // Set copied state to true
  };
  const popoverHoverFocus = (
    <Popover id="popover-basic" style={{ zIndex: 1151 }}>
      <PopoverHeader>Student CEFR Levels</PopoverHeader>
      <PopoverBody>
        <p>
          Click here to see official CEFR self-assessment tables on the Council
          of Europe website.
        </p>
      </PopoverBody>
    </Popover>
  );

  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Student Quick Add</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <h5 className="fw-bold text-pongo mb-4 text-uppercase">
            Students scan Here in mobile app
          </h5>
          <QRCodeSVG value={url} size={200} />
          <p className="text-tiny text-secondary text-center m-0 mt-4">
            Students can join your class using the QRcode.
          </p>
          <p className="text-tiny text-secondary text-center m-0">
            They must know their{" "}
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="right"
              overlay={popoverHoverFocus}>
              <a
                target="_blank"
                href="https://www.coe.int/en/web/portfolio/self-assessment-grid"
                rel="noreferrer"
                className="cursor-pointer text-underline fw-bold">
                CEFR level
              </a>
            </OverlayTrigger>
            &nbsp;to complete this step.
          </p>
          {/* <div className="or-line my-4">
            <hr className="or-line__hr" />
            <span className="or-line__text">OR</span>
            <hr className="or-line__hr" />
          </div>
          <h5 className="fw-bold text-pongo mb-4 text-uppercase">
            Visit this website
          </h5>
          <div className="d-flex align-items-center justify-content-center mb-3">
            <h5 className="mb-0 me-2 text-code">{url}</h5>
            {!copied && (
              <MDBTooltip tag="div" placement="bottom" title={"Copy"}>
                <Clipboard
                  onClick={copyToClipboard}
                  style={{ cursor: "pointer" }}
                />
              </MDBTooltip>
            )}
            {copied && <CheckCircle className="text-success" />}{" "}
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={`countdown ${countdown === "00:00" && "text-danger"}`}>
          Expires in: {countdown}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
