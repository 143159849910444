import React from "react";
import { Button } from "react-bootstrap";
import { PropagateLoader } from "react-spinners";
import { COLORS } from "../../../utils/colors";
import Loading from "../../Loading";

export default function ConfirmationSection({
  isFormValid,
  showConfirmation,
  setShowConfirmation,
  duration,
  getFutureDate,
  submitHandler,
  isLoading,
  t,
}) {
  return !showConfirmation ? (
    <div className="d-flex justify-content-center">
      <Button
        variant="primary"
        disabled={!isFormValid}
        onClick={() => setShowConfirmation(true)}>
        {t("risks.startButton")}
      </Button>
    </div>
  ) : isLoading ? (
    <div className="my-2 d-flex flex-column justify-content-center align-items-center">
      <PropagateLoader color={COLORS[0]} />
      <p className="m-0 mt-4 blink-effect">Loading/Chargement</p>
    </div>
  ) : (
    <div className="d-flex flex-column justify-content-center align-items-center mt-2">
      <div className="d-flex flex-row">
        <Button
          variant="secondary"
          className="me-2"
          onClick={() => setShowConfirmation(false)}>
          {t("risks.no")}
        </Button>
        <Button variant="success" onClick={submitHandler} disabled={isLoading}>
          {t("risks.yes")}
        </Button>
      </div>
      <p className="m-0 mt-2">{t("risks.confirmation1")} </p>
      <p className="text-danger">
        {t("risks.confirmation2")}
        <span className="fw-bold ms-1">{getFutureDate(duration)}.</span>
      </p>
    </div>
  );
}
