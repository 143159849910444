import React, { useEffect } from "react";
import ContactForm from "../../components/Contact/ContactForm";

export default function Support() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div>
      <ContactForm />
    </div>
  );
}
