import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceFrown,
  faFaceGrin,
  faFaceMeh,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import TimeAgo from "../../Posts/TimeAgo";
import ShowImage from "../../ImageUpload/ShowImage";
import { RiskTitleCard } from "./RiskTitleCard";
import { risksMasterArray } from "./RiskArray";
import { difficultyRatings } from "./RiskFunctions";
import AudioPlayer from "../../Audio/AudioPlayer";

export default function SeeRiskPosts({ riskPosts, category }) {
  const icons = {
    1: <FontAwesomeIcon icon={faFaceGrin} color={"green"} size="2x" />,
    2: <FontAwesomeIcon icon={faFaceMeh} color={"goldenrod"} size="2x" />,
    3: <FontAwesomeIcon icon={faFaceFrown} color={"red"} size="2x" />,
  };

  const { t } = useTranslation();

  const data = riskPosts
    .map((riskPost) => {
      const matchingItem = risksMasterArray.find(
        (item) => item.id === parseInt(riskPost.riskId)
      );
      return matchingItem ? { ...matchingItem, ...riskPost } : null;
    })
    .filter(Boolean);

  const filteredData = data.filter((item) =>
    category === "all" ? item : item.strand === category
  );

  const mainTitle = `${t(category)}`;

  return (
    <div className="container my-2">
      {filteredData.map((item, index) => {
        const audioSrc = item.audioBoolean
          ? `https://languageprofile.blob.core.windows.net/audio/${item.id}`
          : null;

        const imageSrc = `https://languageprofile.blob.core.windows.net/image/${item.id}`;

        return (
          <div key={index} className="card mb-4">
            <div className="card-body">
              <RiskTitleCard risk={item} seeRisksPage={true} />
            </div>
            <div className="card-footer border-top">
              <div className="d-flex flex-row justify-content-around">
                <div className="d-flex flex-column align-items-center mb-3">
                  <div>
                    <span className="text-muted text-uppercase">
                      {t("difficulty")}
                    </span>
                  </div>
                  <div>{icons[item.difficulty]}</div>
                  <span>{t(difficultyRatings[item.difficulty].text)}</span>
                </div>
                <div className="d-flex flex-column align-items-center mb-3">
                  <div>
                    <span className="text-muted text-uppercase">
                      {t("stress")}
                    </span>
                  </div>
                  <div>{icons[item.stress]}</div>
                  <span>{t(difficultyRatings[item.stress].text)}</span>
                </div>

                <div className="text-center">
                  <span className="text-muted">{t("audio")}</span>
                  {item.audioBoolean && audioSrc && (
                    <AudioPlayer
                      src={audioSrc}
                      timestamp={item.timestamp}
                      statement={item.statement}
                    />
                  )}
                </div>
              </div>
            </div>
            {item.imageBoolean && <ShowImage src={imageSrc} />}
            <div className="card-footer border-top d-flex justify-content-between">
              <TimeAgo timestamp={item.timestamp} />
              <span className="text-muted">#{item.riskId}</span>
              <span className="text-muted">
                +{item.points} {t("risks.pointsAbbreviation")}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
