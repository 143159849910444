import { createContext, useState } from "react";

export const MainContext = createContext({
  setShowSuccessMessage: () => {},
  showSuccessMessage: false,
  setSuccessMessage: () => {},
  successMessage: "",
  setShowErrorMessage: () => {},
  showErrorMessage: false,
  setErrorMessage: () => {},
  setActiveStudent: () => {},
  activeStudent: "",
  setPostsForStudent: () => {},
  postsForStudent: "",
});

function MainProvider({ children }) {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeStudent, setActiveStudent] = useState("");
  const [postsForStudent, setPostsForStudent] = useState("");

  const value = {
    setShowSuccessMessage,
    showSuccessMessage,
    setShowErrorMessage,
    showErrorMessage,
    setSuccessMessage,
    successMessage,
    setErrorMessage,
    errorMessage,
    setActiveStudent,
    activeStudent,
    setPostsForStudent,
    postsForStudent,
  };

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
}

export default MainProvider;
