import React from "react";

export default function RiskTabTitles({ title, subtitle }) {
  return (
    <div>
      <h4 className="fw-bold m-0 text-pongo">{title.toUpperCase()}</h4>
      <p className="text-secondary m-0 mb-3">{subtitle}</p>
    </div>
  );
}
