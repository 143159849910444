import React, { useContext, useEffect, useState } from "react";
import baseUrl from "../../../utils/baseUrl";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../reducers/sessionSlice";
import {
  countByRiskCategory,
  findRiskLevel,
  riskButtons,
} from "./RiskFunctions";
import { Lightning, Trophy } from "react-bootstrap-icons";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { MainContext } from "../../../contexts/MainContext";
import {
  fetchProgressTeacher,
  selectProgressForStudent,
} from "../../../reducers/progressTeacherSlice";
import SeeRiskPosts from "./RiskPosts";
import { appLevelsReverse } from "../../../utils/levels";
import { COLORS } from "../../../utils/colors";
import { useTranslation } from "react-i18next";
import RiskTitle from "./RiskTitle";

export const RiskButtonContainer = ({
  title,
  isActive,
  type = null,
  icon = null,
  numerator = null,
  denominator = null,
  isLoading = false,
  onClick = null,
  isClicked,
}) => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <div
        className={`${
          !isActive && "bg-inactive-button"
        } d-flex flex-row card shadow ${
          !onClick ? "cursor-none" : isActive && "hover-effect"
        } rounded-pill align-items-center px-3 py-2 ${
          isClicked ? "bg-active-button" : ""
        } `}
        onClick={() => onClick && isActive && onClick(title)}>
        {icon && <div className="me-2">{icon}</div>}
        <p
          className={`m-0 ${
            isClicked ? "fw-bold" : "text-secondary"
          } text-small`}>
          {type === "cefrLevels" && appLevelsReverse[title]?.toUpperCase()}
          {type === "strands" && t(`risks.${title}`).toUpperCase()}
          {type === "points" && `X ${title}`}
          {type === "group" && `${title}`}

          {type === "duration" &&
            (title / 7 >= 1
              ? `${Math.floor(title / 7)} ${
                  Math.floor(title / 7) === 1
                    ? t(`risks.week`).toUpperCase()
                    : t(`risks.weeks`).toUpperCase()
                }`
              : `${title} ${t(`risks.day`).toUpperCase()}`)}
        </p>
        <div className="ms-auto d-flex flex-row">
          {isLoading ? (
            <Skeleton height={"0.7rem"} width={20} />
          ) : (
            <>
              {numerator !== null && (
                <p className="m-0 fw-bold text-xs">
                  {numerator !== null ? numerator : 0}
                </p>
              )}
              {denominator && (
                <p className="m-0 ms-auto fw-bold text-xs">
                  &nbsp;/ {denominator ?? "N/A"}
                </p>
              )}
            </>
          )}
        </div>
      </div>
      {type === "group" && !isActive && (
        <p className="pt-2 m-0 text-success text-xs">
          {t("progress.In Progress")}
        </p>
      )}
    </div>
  );
};

const goalCalculations = (data, strand, attribute) => {
  const foundItem = data.find((item) => item.strand === strand);
  return foundItem ? foundItem[attribute] : 0;
};

export default function RiskProfile() {
  const { t } = useTranslation();
  const [riskPosts, setRiskPosts] = useState([]);
  const [data, setData] = useState([]);
  const [riskPoints, setRiskPoints] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const mainCtx = useContext(MainContext);
  const studentId = mainCtx.activeStudent;
  const [category, setCategory] = useState("all");
  const progressData = useSelector(selectProgressForStudent(studentId));

  const buttonHandler = (newCategory) => {
    if (newCategory === category) {
      setCategory("");
    } else {
      setCategory(newCategory);
    }
  };
  const riskLevel = progressData?.[0]?.grade
    ? findRiskLevel(progressData[0].grade)
    : null;

  useEffect(() => {
    if (progressData?.length === 0) {
      dispatch(fetchProgressTeacher({ userId: studentId })).unwrap();
    }
  }, [dispatch, studentId, progressData]);

  // Fetch risk posts when studentId changes
  useEffect(() => {
    const fetchRiskPosts = async (obj) => {
      const settings = {
        body: JSON.stringify(obj),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await fetch(`${baseUrl}/get_risk_posts`, settings);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return await response.json(); // Return the fetched data
      } catch (error) {
        console.error("Error fetching risk posts:", error);
        return []; // Return an empty array on error
      }
    };

    const fetchRiskPoints = async (obj) => {
      const settings = {
        body: JSON.stringify(obj),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await fetch(`${baseUrl}/get_risk_points`, settings);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return await response.json(); // Return the fetched data
      } catch (error) {
        console.error("Error fetching risk points:", error);
        return []; // Return an empty array on error
      }
    };

    const fetchAllData = async () => {
      setLoading(true); // Start loading state
      setRiskPosts([]); // Reset risk posts
      setData([]); // Reset data
      setRiskPoints("");

      // Fetch risk posts
      const posts = await fetchRiskPosts({ userId: studentId });
      setRiskPosts(posts); // Update state with fetched risk posts

      // Fetch risk points
      const points = await fetchRiskPoints({ studentId: studentId });
      setRiskPoints(points); // Update state with fetched risk posts

      // Fetch additional data if riskLevel is defined
      if (riskLevel !== null) {
        const rawData = await countByRiskCategory(
          posts,
          { id: studentId, district_id: user.district_id },
          riskLevel
        );
        setData(rawData); // Update data after processing risk posts
      }

      setLoading(false); // End loading state
    };

    // Only fetch data if studentId is defined and riskLevel is determined
    if (studentId && riskLevel !== null) {
      fetchAllData();
    }

    // Cleanup function
    return () => {
      setRiskPosts([]);
      setData([]);
      setRiskPoints("");
    };
  }, [studentId, riskLevel, user.district_id]);

  const overall = data.find((item) => item.strand === "overall");

  return (
    <div>
      <div className="row mb-3">
        <RiskTitle pb={2} />

        <div className="row mx-auto">
          {riskButtons.map((button) => (
            <div className="col-4 mb-2" key={button.strand}>
              <RiskButtonContainer
                type="strands"
                isActive={true}
                title={button.strand}
                isClicked={category === button.strand}
                icon={button.icon}
                isLoading={loading}
                onClick={buttonHandler}
                numerator={
                  button.strand === "all"
                    ? overall?.totalCompletedRisks
                    : goalCalculations(data, button.strand, "uniqueCompleted")
                }
                denominator={
                  button.strand === "all"
                    ? null
                    : goalCalculations(data, button.strand, "total").toString()
                }
              />
            </div>
          ))}
          <div className="col-4">
            <RiskButtonContainer
              title={"unique"}
              type="strands"
              icon={<Lightning color={COLORS[0]} size={20} />}
              denominator={overall?.totalRisks}
              numerator={overall?.totalUniqueCompletedRisks}
              isLoading={loading}
              isClicked={category === "unique"}
              isActive={true}
            />
          </div>
          <div className="col-4">
            <RiskButtonContainer
              type="strands"
              title={"total points"}
              icon={<Trophy color={COLORS[0]} size={20} />}
              numerator={`${riskPoints.points}pts`}
              isLoading={loading}
              isActive={true}
            />
          </div>
        </div>
      </div>

      <div
        className="min-vh-25 card bg-gray mx-2 bg-main"
        style={{ minHeight: "20vh" }}>
        {loading ? (
          <div className="text-center py-3">Loading/Chargement</div>
        ) : riskPosts.length === 0 ? (
          <div className="text-center py-3">{t("risks.noPosts")}</div>
        ) : (
          <SeeRiskPosts riskPosts={riskPosts} category={category} />
        )}
      </div>
    </div>
  );
}
