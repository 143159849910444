import React from "react";
import { RiskButtonContainer } from "./RiskProfile";
import { isPaloozaActiveForGroup } from "./RiskFunctions";
import { useSelector } from "react-redux";
import { selectRiskPaloozas } from "../../../reducers/riskPaloozasSlice";

export default function RiskCard({
  title,
  options,
  isSelected,
  onClick,
  type,
}) {
  const riskPaloozas = useSelector(selectRiskPaloozas);

  const getCardClass = (isHighlighted) =>
    `card py-3 mb-3 px-3 ${isHighlighted ? "bg-pongo-light-green" : ""}`;

  const renderOption = (option, index) => {
    const isActive =
      type === "group"
        ? !isPaloozaActiveForGroup(option.id, riskPaloozas)
        : true;

    const optionTitle =
      type === "strands" ? option.strand : option.name || option;

    return (
      <div className="me-2 mb-1" key={index}>
        <RiskButtonContainer
          isActive={isActive}
          title={optionTitle}
          type={type}
          isClicked={isSelected(option)}
          onClick={() => onClick(option)}
          icon={option.icon}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (type === "group" && options.length === 0) {
      return <div>No groups available. You must add student groups.</div>;
    }

    return options.map(renderOption);
  };

  const isHighlighted = options.some((option) => isSelected(option));

  return (
    <div className={getCardClass(isHighlighted)}>
      <p className="fw-bold m-0">{title}</p>
      <div className="d-flex flex-wrap my-2">{renderContent()}</div>
    </div>
  );
}
