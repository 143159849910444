import React, { useEffect, useState } from "react";
import {
  filterRisksByStrand,
  riskButtons,
  riskLevels,
} from "../../components/TeacherWall/Risks/RiskFunctions";
import { risksMasterArray } from "../../components/TeacherWall/Risks/RiskArray";
import LeftPanel from "../../components/TeacherWall/LeftPanel";
import RightPanel from "../../components/TeacherWall/RightPanel";
import { useSelector } from "react-redux";
import { selectTeacherPermissions } from "../../reducers/teacherPermissionsSlice";
import { useTranslation } from "react-i18next";
import RiskTitle from "../../components/TeacherWall/Risks/RiskTitle";
import { Tab, Tabs } from "react-bootstrap";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import RiskSettings from "../../components/TeacherWall/Risks/RiskSettings";
import { RiskButtonContainer } from "../../components/TeacherWall/Risks/RiskProfile";
import RiskTabTitles from "../../components/TeacherWall/Risks/RiskTabTitles";

export default function RiskPortfolio() {
  const [category, setCategory] = useState("reading");
  const [level, setLevel] = useState(0);
  const [filter, setFilter] = useState(risksMasterArray);
  const teacherPermissions = useSelector(selectTeacherPermissions);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const categoryButtonHandler = (newCategory) => {
    if (newCategory === category) {
      setCategory("");
    } else {
      setCategory(newCategory);
    }
  };
  const levelButtonHandler = (newLevel) => {
    if (newLevel === level) {
      setLevel("");
    } else {
      setLevel(newLevel);
    }
  };
  useEffect(() => {
    const filteredItems = filterRisksByStrand(level, category);
    setFilter(filteredItems);
  }, [setFilter, category, level]);
  const [activeTab, setActiveTab] = useState("riskList");
  const handleSelect = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="row">
      <div className="col-lg-2 d-none d-lg-block">
        <LeftPanel />
      </div>
      <div className="col-lg-7 col-12 bg-white p-3 rounded">
        <Tabs
          defaultActiveKey={activeTab}
          id="uncontrolled-tab-example"
          onSelect={handleSelect}>
          <Tab eventKey="riskList" title={<RiskTitle size="xs" />}>
            <div className="card p-3 bg-light my-3">
              <RiskTabTitles
                title={t("risks.risks")}
                subtitle={t("risks.listRisks")}
              />
              <p className="m-0 mx-2 fw-bold text-secondary">
                {t(`classList.Filter by`)}
              </p>
              <div className="col-12 d-flex flex-row">
                {riskLevels.map((riskLevel) => (
                  <div className="m-2" key={riskLevel}>
                    <RiskButtonContainer
                      title={riskLevel}
                      type="cefrLevels"
                      isClicked={level === riskLevel}
                      onClick={levelButtonHandler}
                      isActive={true}
                    />
                  </div>
                ))}
              </div>
              <div className="col-12 d-flex flex-row">
                {riskButtons.map(
                  (button, i) =>
                    button.strand !== "all" && (
                      <div className="m-1" key={i}>
                        <RiskButtonContainer
                          title={button.strand}
                          isClicked={category === button.strand}
                          icon={button.icon}
                          onClick={categoryButtonHandler}
                          type="strands"
                          isActive={true}
                        />
                      </div>
                    )
                )}
              </div>
            </div>

            <div className="col-12 bg-white card mx-auto p-2 mx-2">
              <div className="row py-1 border-bottom border-4 p-2">
                <div className="col-1 text-uppercase text-small">ID</div>
                <div className="col-5 text-uppercase text-small">
                  {t("risks.risk")} (EN)
                </div>
                <div className="col-5 text-uppercase text-small">
                  {t("risks.risk")} (FR)
                </div>
                <div className="col-1 text-uppercase text-small">Pts</div>
              </div>
              {filter.map((item, i) => (
                <div key={i} className="row py-1 border-bottom p-2">
                  <div className="col-1 text-small text-secondary">
                    {item.id}
                  </div>
                  <div className="col-5 text-small">{item.risk_en}</div>
                  <div className="col-5 text-small">{item.risk_fr}</div>
                  <div className="col-1 text-secondary text-xs">
                    {item.points}
                  </div>
                </div>
              ))}
            </div>
          </Tab>
          <Tab
            eventKey={"riskLeaderboard"}
            title={
              <div className="d-flex flex-row">
                <p className="m-0">{t(`risks.Risk Rankings`)} </p>
              </div>
            }>
            <div className="card bg-light my-3 p-3">
              <RiskTabTitles
                title={t("risks.Risk Rankings")}
                subtitle={t("risks.ranksSubtitle")}
              />
              <div className="my-2">
                <Leaderboard
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
                <div className="mt-2">
                  <Leaderboard
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    timeFrame="year"
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey={"riskSettings"} title={t(`risks.settings`)}>
            <RiskSettings />
          </Tab>
        </Tabs>
      </div>
      <div className="col-lg-3 d-none d-lg-block">
        <RightPanel teacherPermissions={teacherPermissions} type="risks" />
      </div>
    </div>
  );
}
