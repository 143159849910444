import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HomePageContainer = ({ title, text, icon, iconColor }) => {
  return (
    <div
      className="card col-lg-3 col-12 mx-lg-5 m-2 p-5 d-flex align-items-start my-auto h-100"
      style={{ borderRadius: "30px" }}>
      <FontAwesomeIcon icon={icon} size="5x" color={iconColor} />
      <h5 className="pt-3 text-uppercase fw-bold text-start my-3">{title}</h5>
      <h4 className="fw-200 text-start m-0">{text}</h4>
    </div>
  );
};

HomePageContainer.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
};

HomePageContainer.defaultProps = {
  iconColor: "#000",
};

export default HomePageContainer;
