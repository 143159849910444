import { MDBTooltip } from "mdb-react-ui-kit";
import React from "react";
import { QuestionCircle } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function RiskTitle({ size = null, pb = 0 }) {
  const { t } = useTranslation();
  return (
    <div className={`d-flex flex-row justify-content-center pb-${pb}`}>
      <p className={`text-cente m-0 ${size === null && "h3"}`}>
        {t(`risks.risks`)}
      </p>

      <span className="ms-1">
        <MDBTooltip tag="div" placement="top" title={t(`risks.risksInfo`)}>
          <QuestionCircle />
        </MDBTooltip>
      </span>
    </div>
  );
}
