import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./ProfileStyles.css";
import { useDispatch } from "react-redux";
import { changeTeacherPermissions } from "../../reducers/teacherPermissionsSlice";
import { changeTextField } from "../../reducers/sessionSlice";
import SnapchatLogin from "./Snapchat/SnapchatLogin";

export default function ProfilePictureModal(props) {
  const dispatch = useDispatch();

  const [selectAvatar, setSelectAvatar] = useState(null);

  const profileAvatars = [
    {
      category: "Animals",
      uri: "/static/images/avatars/animals",
      avatars: [
        { description: "ape", src: `ape.jpg` },
        { description: "cat", src: `cat.jpg` },
        { description: "dog", src: `dog.jpg` },
        { description: "fox", src: `fox.jpg` },
        { description: "hare", src: `hare.jpg` },
        { description: "koala", src: `koala.jpg` },
        { description: "lion", src: `lion.jpg` },
        { description: "tiger", src: `tiger.jpg` },
      ],
    },
    {
      category: "Emojis",
      uri: "/static/images/avatars/emojis",
      avatars: [
        { description: "Very Happy", src: `1F601.png` },
        { description: "crying Happy", src: `1F602.png` },
        { description: "happy", src: `1F603.png` },
        { description: "squinty happy", src: `1F604.png` },
        { description: "sweat happy", src: `1F605.png` },
        { description: "caret eyes happy", src: `1F606.png` },
        { description: "angel happy", src: `1F607.png` },
        { description: "devil happy", src: `1F608.png` },
        { description: "wink happy", src: `1F609.png` },
        { description: "indifferent", src: `1F610.png` },
        { description: "indifferent eyes closed", src: `1F611.png` },
        { description: "apprehensive", src: `1F612.png` },
        { description: "uncomfortable tear", src: `1F613.png` },
        { description: "upset", src: `1F614.png` },
        { description: "somewhat upset", src: `1F615.png` },
        { description: "emoji", src: `1F616.png` },
        { description: "emoji", src: `1F617.png` },
        { description: "emoji", src: `1F618.png` },
        { description: "emoji", src: `1F619.png` },
        { description: "emoji", src: `1F620.png` },
        { description: "emoji", src: `1F621.png` },
        { description: "emoji", src: `1F622.png` },
        { description: "emoji", src: `1F623.png` },
        { description: "emoji", src: `1F624.png` },
        { description: "emoji", src: `1F625.png` },
        { description: "emoji", src: `1F626.png` },
        { description: "emoji", src: `1F627.png` },
        { description: "emoji", src: `1F628.png` },
        { description: "emoji", src: `1F629.png` },
        { description: "emoji", src: `1F630.png` },
        { description: "emoji", src: `1F631.png` },
        { description: "emoji", src: `1F632.png` },
        { description: "emoji", src: `1F633.png` },
        { description: "emoji", src: `1F634.png` },
        { description: "emoji", src: `1F635.png` },
      ],
    },
  ];

  const onSelect = (value) => {
    setSelectAvatar(value);
  };
  const onSubmit = () => {
    props.modal
      ? dispatch(
          changeTeacherPermissions({
            id: props.id,
            attribute_name: "profile_pic_src",
            content: selectAvatar,
          })
        ).unwrap()
      : dispatch(
          changeTextField({
            id: props.id,
            attribute_name: "profile_pic_src",
            content: selectAvatar,
          })
        ).unwrap();

    props.setshow(false);
  };
  const animalAvatarPics = (
    <>
      {profileAvatars.map((item, i) => (
        <div key={i} className="mb-3">
          <h5 className="text-muted text-center">{item.category}</h5>
          <div className="row mx-auto">
            {item.avatars.map((avatar, j) => (
              <div key={j} className="col-auto m-1 mx-auto">
                <label key={avatar.description}>
                  <input
                    type="radio"
                    name="avatar"
                    value={`${item.uri}/${avatar.src}`}
                    onChange={(e) => onSelect(e.target.value)}
                    checked={`${item.uri}/${avatar.src}` === selectAvatar}
                  />
                  <img
                    src={`https://language-profile.azurewebsites.net/${item.uri}/${avatar.src}`}
                    alt={avatar.description}
                    loading="lazy"
                    style={{ height: "90px", width: "90px" }}
                  />
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>Change Profile Picture</Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-1">
        {/* <div className="mb-3">
          <h5 className="text-muted text-center">BitMoji</h5>
          <SnapchatLogin
            onSelect={onSelect}
            selectAvatar={selectAvatar}
            setSelectAvatar={setSelectAvatar}
          />
        </div> */}

        {animalAvatarPics}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          variant="primary"
          onClick={onSubmit}
          disabled={!selectAvatar}>
          Change Picture
        </button>
      </Modal.Footer>
    </Modal>
  );
}
