import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PropagateLoader } from "react-spinners";
import { selectUser, setSession } from "../reducers/sessionSlice";
import { AuthContext } from "../contexts/AuthContext";
import { COLORS } from "../utils/colors";

export const loadingDiv = (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}>
    <div className="d-flex flex-row">
      <PropagateLoader size={30} color={COLORS[0]} />
    </div>
  </div>
);

export default function Loading() {
  const sessionStatus = useSelector((state) => state.session.status);
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const isAuthenticated = authCtx.isAuthenticated;
  const graphData = authCtx.token;
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (isAuthenticated && graphData) {
      if (sessionStatus === "idle" || sessionStatus === "failed") {
        dispatch(setSession(graphData.mail));
      }
    } else {
      navigate("/");
    }
  }, [isAuthenticated, graphData, sessionStatus, dispatch, navigate]);

  useEffect(() => {
    if (user) {
      console.log("Connecting to socket on server-side.");
      navigate("/wall");

      // Disconnect existing socket before connecting a new one
      authCtx.disconnectSocket(); // Ensure the old socket is disconnected
      authCtx.connectSocket(user.id); // Connect a new socket
    }
  }, [user, navigate, authCtx]);

  return loadingDiv;
}
