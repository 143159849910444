import React, { useState, useEffect, useContext } from "react";
import "./NavBar.css";
import {
  faHome,
  faBell,
  faRobot,
  faChalkboardUser,
  faChartColumn,
} from "@fortawesome/free-solid-svg-icons";
import "./buttons/ButtonStyles.css";
import { ButtonTemplate } from "./buttons/ButtonTemplate";
import { AccountButton } from "./buttons/AccountButton";
import Navbar from "react-bootstrap/Navbar";
import { SignInButton } from "../SignInButton";
import { SignOutButton } from "../SignOutButton";
import { useTranslation } from "react-i18next";
import ProgressButton from "./buttons/ProgressButton";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSession,
  selectUser,
  setSession,
} from "../../reducers/sessionSlice";
import SearchBar from "./SearchBar";
import { Link } from "react-router-dom";
import NotificationsModal from "../Notifications/NotificationsModal";
import {
  clearNotifications,
  fetchNotifications,
} from "../../reducers/notificationsSlice";
import SignUpButton from "./SignUpButton";
import LanguageToggle from "./LanguageToggle";
import { AdminPages } from "./buttons/AdminPages";
import logoLightbulb from "../../assets/logo/logo_lightbulb.png";
import logoText from "../../assets/logo/logo_text.png";
import { AuthContext } from "../../contexts/AuthContext";
import { clearProgress } from "../../reducers/progressSlice";
import { clearAdminMetrics } from "../../reducers/adminMetricsSlice";
import { clearStudentPermissions } from "../../reducers/studentPermissionsSlice";
import { clearPosts } from "../../reducers/postsSlice";
import { useNavigate } from "react-router";

export default function NavBar(props) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const isAuthenticated = authCtx.isAuthenticated;
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userFound = !!user;
  const { t } = useTranslation();
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationsStatus = useSelector(
    (state) => state.notifications.status
  );
  useEffect(() => {
    console.log("notificationsStatus:", notificationsStatus);
    console.log("user:", user);

    if (notificationsStatus === "idle" && !!user) {
      console.log("Dispatching fetchNotifications with user id:", user?.id);
      dispatch(fetchNotifications(user.id));
    }
  }, [notificationsStatus, user, dispatch]);

  return (
    <>
      <Navbar expand="lg" className="px-3 navbar">
        <div className="row vw-100 py-2">
          <div className="col-1 col-lg-3 d-flex flex-row">
            {isAuthenticated && (
              <Link to="/">
                <img
                  src={logoLightbulb}
                  className="navbar-img mt-1 me-2"
                  alt="logo"
                />
              </Link>
            )}
            <span className="d-none d-lg-block me-auto h5 my-auto">
              {user?.role === "Teacher" ? (
                <div className="ms-3">
                  <SearchBar />
                </div>
              ) : (
                <Link to="/">
                  <img
                    src={logoText}
                    className="navbar-logo-text ms-1"
                    alt="logo"
                  />
                </Link>
              )}
            </span>
          </div>
          <div className="col-1 col-lg-6 my-auto">
            {user &&
              user?.email &&
              user?.email.toLowerCase() !==
                authCtx.token?.mail.toLowerCase() && (
                <div
                  onClick={() => {
                    authCtx.disconnectSocket();
                    dispatch(clearSession());
                    navigate("/");
                    dispatch(setSession(authCtx.token?.mail));
                    dispatch(clearNotifications());
                    dispatch(clearProgress());
                    dispatch(clearStudentPermissions());
                    dispatch(clearPosts());
                    dispatch(clearAdminMetrics());
                  }}>
                  <span className="d-none d-lg-block me-auto h5 my-auto text-center text-pongo cursor-pointer">
                    Exit Chameleon Mode
                  </span>
                </div>
              )}
          </div>

          <div className="ms-auto d-flex justify-content-end col-10 col-lg-3">
            {isAuthenticated && userFound ? (
              <>
                <ButtonTemplate
                  title={t("navbar.Home")}
                  href="/wall"
                  icon={faHome}></ButtonTemplate>
                {/* Show AdminPages on Tablet Screen Size or Smaller */}
                <div className="d-lg-none">
                  {user?.role === "Administrator" && <AdminPages />}
                </div>

                {user?.role === "Teacher" && (
                  <>
                    <ButtonTemplate
                      id="classList"
                      title={t("navbar.Class List")}
                      href="/class_list"
                      icon={faChalkboardUser}
                    />
                    <ButtonTemplate
                      title={t("navbar.Metrics")}
                      href="/metrics"
                      icon={faChartColumn}
                    />
                    <ButtonTemplate
                      id="lessonGenerator"
                      title={t("navbar.Artificial Intelligence")}
                      href="/activity_finder"
                      icon={faRobot}
                    />
                  </>
                )}
                {user?.role === "Student" && (
                  <>
                    <ProgressButton />
                  </>
                )}

                <ButtonTemplate
                  title="Notifications"
                  href={null}
                  icon={faBell}
                  onClick={() => setShowNotifications(true)}
                />
                <AccountButton />
              </>
            ) : (
              <>
                {isAuthenticated ? (
                  <>
                    {!user && <LanguageToggle />}
                    <SignOutButton />
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-row align-items-center">
                      <div className="d-flex flex-row">
                        <LanguageToggle />
                        <SignUpButton />
                        <SignInButton />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Navbar>

      {showNotifications && isAuthenticated && (
        <NotificationsModal
          show={showNotifications}
          onHide={() => setShowNotifications(false)}
        />
      )}
    </>
  );
}
