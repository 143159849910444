import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";
import { clearReduxStore } from "../store/globalActions";

// Fetch Risk Paloozas with error handling
export const fetchRiskPaloozas = createAsyncThunk(
  "riskPaloozas/fetchRiskPaloozas",
  async (object, { rejectWithValue }) => {
    try {
      const response = await fetch(`${baseUrl}/get_risk_paloozas`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch risk paloozas");
      }

      const paloozas = await response.json();
      return paloozas.result;
    } catch (error) {
      console.error("Error in fetchRiskPaloozas:", error);
      return rejectWithValue(error.message || "An unexpected error occurred");
    }
  }
);

// Add Risk Palooza with error handling
export const addRiskPalooza = createAsyncThunk(
  "riskPaloozas/addRiskPalooza",
  async (object, { rejectWithValue }) => {
    try {
      const response = await fetch(`${baseUrl}/add_risk_palooza`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      });

      if (!response.ok) {
        throw new Error("Failed to add risk palooza");
      }

      const new_palooza = await response.json();
      return new_palooza.result; // Assuming 'result' is the key for the newly added palooza
    } catch (error) {
      console.error("Error in addRiskPalooza:", error);
      return rejectWithValue(error.message || "An unexpected error occurred");
    }
  }
);

const initialState = {
  riskPaloozas: [],
  status: "idle",
  error: null,
};

const riskPaloozasSlice = createSlice({
  name: "riskPaloozas",
  initialState,
  reducers: {
    clearRiskPalooza: () => {
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRiskPaloozas.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRiskPaloozas.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.riskPaloozas = state.riskPaloozas.concat(action.payload);
      })
      .addCase(fetchRiskPaloozas.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message; // Handle rejected action payload
      })
      .addCase(addRiskPalooza.fulfilled, (state, action) => {
        // Check if the payload is an array and push each item separately
        if (Array.isArray(action.payload)) {
          state.riskPaloozas.push(...action.payload); // Spread syntax to add each item
        } else {
          state.riskPaloozas.push(action.payload); // If it's not an array, push the single item
        }
      })
      .addCase(addRiskPalooza.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message; // Handle rejected action payload
      })
      .addCase(clearReduxStore, () => initialState);
  },
});

export default riskPaloozasSlice.reducer;

export const { clearRiskPalooza } = riskPaloozasSlice.actions;

export const selectRiskPaloozas = (state) => {
  return state.riskPaloozas.riskPaloozas
    .slice()
    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
};
