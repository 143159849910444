import React, { useContext, useEffect, useState } from "react";
import {
  ArrowLeft,
  ExclamationTriangle,
  HandThumbsDown,
  HandThumbsUp,
} from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import AddStudentModal from "../components/AddStudent/AddStudentModal";
import { AuthContext } from "../contexts/AuthContext";
import { useMsal } from "@azure/msal-react";
import { handleMicrosoftLogin } from "../components/SignInButton";
import { useTranslation } from "react-i18next";
import baseUrl from "../utils/baseUrl";

function QuickSignUp() {
  let { variable } = useParams();
  const { i18n } = useTranslation();
  const authCtx = useContext(AuthContext);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showNoMessage, setShowNoMessage] = useState(false);
  const { instance } = useMsal();
  useEffect(() => {
    if (authCtx.isAuthenticated && data.success) {
      setShowModal(true);
    }
  }, [authCtx.isAuthenticated, setShowModal, data.success]);

  const yesButtonHandler = () => {
    authCtx.isAuthenticated
      ? setShowModal(true)
      : handleMicrosoftLogin(instance, "popup");
  };

  // Handle if user is signed into microsoft

  // const microsoftAuthenticated = useIsAuthenticated();

  // useEffect(() => {
  //   if (microsoftAuthenticated) {
  //     authCtx.logout(instance);
  //   }
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/verify_key/${variable}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        setData(responseData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [variable]);

  return (
    <>
      <div className="d-flex flex-column align-items-center vh-80 justify-content-center">
        {!!data && data.success && (
          <>
            <img
              alt="Logo"
              id="main-image"
              className="mx-auto d-block"
              src="https://language-profile.azurewebsites.net/static/images/main.png"
            />
            <h4 className="text-pongo text-center mt-3 fw-bold">
              {i18n.language === "en"
                ? "Welcome to Pongo"
                : "Bienvenue à Pongo"}
            </h4>

            <h2 className="fw-300 mt-3">
              {i18n.language === "en"
                ? "Do you know your CEFR level?"
                : "Sais-tu tes niveaux de compétences selon le CECR?"}
            </h2>

            {showNoMessage && (
              <h2 className="fw-bold my-3">
                {i18n.language === "en"
                  ? "Please ask your teacher for your CEFR levels."
                  : "Demande à ton enseignant pour t'aider."}
              </h2>
            )}
            <div className="d-flex justify-content-center mt-4">
              {showNoMessage ? (
                <button
                  type="button"
                  className="btn btn-primary btn-lg me-2"
                  onClick={() => {
                    setShowNoMessage(false);
                  }}>
                  <ArrowLeft className="me-1" />{" "}
                  {i18n.language === "en" ? "Back" : "Reculer"}
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-success btn-lg me-2"
                    onClick={yesButtonHandler}>
                    <HandThumbsUp className="me-1" />{" "}
                    {i18n.language === "en" ? "Yes" : "Oui"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-lg"
                    onClick={() => setShowNoMessage(true)}>
                    <HandThumbsDown className="me-1" />{" "}
                    {i18n.language === "en" ? "No" : "Non"}
                  </button>
                </>
              )}
            </div>
          </>
        )}
        {!loading && !data.success && (
          <>
            <div className="d-flex justify-content-center mt-5">
              <ExclamationTriangle
                className="text-danger mb-3"
                style={{ fontSize: "6rem" }}
              />
            </div>
            <h1 className="text-center fw-bold mb-2 text-danger">
              Oh no! What happened?
            </h1>

            <h5 className="text-center">
              Ask your teacher to make you a new QR key or link.
            </h5>
            <h5 className="text-center">Your link no longer works.</h5>
          </>
        )}
      </div>
      {!!data && showModal && (
        <AddStudentModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          district_levels={data?.district_levels}
          school={data?.school}
          school_id={data?.school_id}
          primary_teacher_id={data?.primary_teacher_id}
          district_goals={data?.district_goals}
          district_id={data?.district_id}
          presetEmail={authCtx.token.mail}
        />
      )}
    </>
  );
}

export default QuickSignUp;
