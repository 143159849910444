import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { MainContext } from "../contexts/MainContext";

const ErrorMessage = ({ duration = 10000, onClose }) => {
  const mainCtx = useContext(MainContext);
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div
      className="alert alert-danger bg-danger bg-gradient bg-opacity-75 fixed-bottom mb-0 d-flex justify-content-between align-items-center"
      role="alert">
      <div className="d-flex flex-row align-items-center justify-content-center mx-auto">
        <FontAwesomeIcon icon={faX} color="white" className="me-2" />
        <p className="text-center m-0 text-white fw-bold">
          {mainCtx.errorMessage ||
            "Operation unsuccessful. Try again. / Opération non-réussie. Réessayer,"}
        </p>
      </div>
      <button
        type="button"
        className="btn-close"
        onClick={onClose}
        aria-label="Close"></button>
    </div>
  );
};

export default ErrorMessage;
