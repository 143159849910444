import React, { useState } from "react";
import DistrictGoalsTable from "../../components/ActivityFinder/DistrictGoalsTable";
import { useTranslation } from "react-i18next";
import ActivityFinderModal from "../../components/ActivityFinder/ActivityFinderModal";
import LeftPanel from "../../components/TeacherWall/LeftPanel";

export default function ActivityFinder() {
  const { t } = useTranslation();
  const [objective, setObjective] = useState("");
  const [showActivityFinderModal, setShowActivityFinderModal] = useState(false);
  const closeActivityFinder = () => {
    setObjective("");
    setShowActivityFinderModal(false);
  };

  return (
    <div className="d-flex flex-row">
      <div className="col-2">
        <LeftPanel />
      </div>
      <div className="row">
        <div className="d-flex justify-content-center">
          <div className="col-lg-10">
            <h5 className="text-center m-0 text-uppercase text-secondary">
              {t("lessonGenerator.AI Lesson Generator")}
            </h5>
            <p className="text-center p-2">
              {t("lessonGenerator.Click on a goal to search for a lesson")}
            </p>
            <DistrictGoalsTable
              setObjective={setObjective}
              setShowActivityFinderModal={setShowActivityFinderModal}
            />
            {showActivityFinderModal && (
              <ActivityFinderModal
                objective={objective}
                show={showActivityFinderModal}
                handleClose={closeActivityFinder}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
