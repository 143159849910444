import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../../reducers/sessionSlice";
import {
  fetchGroupings,
  selectGroupNames,
} from "../../../reducers/groupingsSlice";
import RiskCard from "./RiskCard";
import ConfirmationSection from "./ConfirmationSection";
import {
  formatTimestamp,
  isPaloozaActive,
  riskButtons,
  riskStrands,
} from "./RiskFunctions";
import {
  addRiskPalooza,
  fetchRiskPaloozas,
  selectRiskPaloozas,
} from "../../../reducers/riskPaloozasSlice";
import { MainContext } from "../../../contexts/MainContext";
import { loadingDiv } from "../../Loading";
import { getGroupName } from "../../ClassListTable/ClassListTable";
import RiskTabTitles from "./RiskTabTitles";

export default function RiskSettings() {
  const [category, setCategory] = useState([]);
  const [duration, setDuration] = useState("");
  const [group, setGroup] = useState([]);
  const [pointMultiplier, setPointMultiplier] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const resetForm = () => {
    setCategory([]);
    setDuration("");
    setGroup([]);
    setPointMultiplier("");
    setShowConfirmation(false);
  };
  const [isLoading, setIsLoading] = useState(true);
  const durations = [1, 7, 14, 21];
  const pointMultipliers = [2, 3, 4];
  const user = useSelector(selectUser);
  const groupingsStatus = useSelector((state) => state.groupings.status);
  const riskPaloozasStatus = useSelector((state) => state.riskPaloozas.status);
  const groupings = useSelector(selectGroupNames);
  const groupingsIds = groupings.map((group) => group.id);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const mainCtx = useContext(MainContext);
  const riskPaloozas = useSelector(selectRiskPaloozas);
  const [submissionLoading, setSubmissionLoading] = useState(false);

  useEffect(() => {
    if (groupingsStatus === "idle") {
      dispatch(fetchGroupings(user.id));
    }
  }, [groupingsStatus, dispatch, user.id]);

  useEffect(() => {
    if (riskPaloozasStatus === "idle" && groupingsIds.length > 0) {
      dispatch(fetchRiskPaloozas({ groupingsIds: groupingsIds }));
    }
    setIsLoading(false);
  }, [riskPaloozasStatus, groupingsIds, dispatch]);

  const toggleSelection = (current, selection, setter) => {
    setter(current === selection ? "" : selection);
    setShowConfirmation(false);
  };

  const categoryButtonHandler = (strand) => {
    setCategory((prevCategory) =>
      prevCategory.includes(strand)
        ? prevCategory.filter((item) => item !== strand)
        : [...prevCategory, strand]
    );
    setShowConfirmation(false);
  };

  const groupButtonHandler = (groupId) => {
    setGroup((prevCategory) =>
      prevCategory.includes(groupId)
        ? prevCategory.filter((item) => item !== groupId)
        : [...prevCategory, groupId]
    );
    setShowConfirmation(false);
  };

  const isFormValid =
    Boolean(pointMultiplier) &&
    category.length > 0 &&
    Boolean(duration) &&
    group.length > 0;

  const getFutureDate = (duration) => {
    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + duration);

    return futureDate.toLocaleDateString(i18n.language, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const submitHandler = async () => {
    setSubmissionLoading(true);
    if (!isFormValid) {
      console.error(t("risks.formInvalid"));
      return;
    }

    const formData = {
      teacherId: user.id,
      strands: category,
      duration: duration,
      multiplier: pointMultiplier,
      groupingsIds: group,
    };

    try {
      // Dispatch the action and unwrap the result
      await dispatch(addRiskPalooza(formData)).unwrap();

      // Set success message on success
      mainCtx.setSuccessMessage(t("risks.successMessage"));
      mainCtx.setShowSuccessMessage(true);
      resetForm();
    } catch (error) {
      // Set error message if dispatch fails
      console.error("Dispatch failed:", error);
      mainCtx.setErrorMessage(t("risks.errorMessage"));
      mainCtx.setShowErrorMessage(true);
    }
    setSubmissionLoading(false);
  };

  return isLoading ? (
    loadingDiv
  ) : (
    <>
      <div className="card p-3 bg-light my-3">
        <RiskTabTitles
          title={t("risks.multiplyTitle")}
          subtitle={t("risks.multiplySubtitle")}
        />

        <RiskCard
          title={t("risks.durationTitle")}
          type={"duration"}
          options={durations}
          isSelected={(option) => duration === option}
          onClick={(selection) =>
            toggleSelection(duration, selection, setDuration)
          }
        />

        <RiskCard
          title={t("risks.multiplierTitle")}
          options={pointMultipliers}
          type={"points"}
          isSelected={(option) => pointMultiplier === option}
          onClick={(selection) =>
            toggleSelection(pointMultiplier, selection, setPointMultiplier)
          }
        />

        <RiskCard
          title={t("risks.groupTitle")}
          options={groupings}
          type={"group"}
          isSelected={(option) => group.includes(option.id)}
          onClick={(option) => groupButtonHandler(option.id)}
        />

        <RiskCard
          title={t("risks.strandsTitle")}
          type={"strands"}
          options={riskButtons.filter((button) => button.strand !== "all")}
          isSelected={(option) => category.includes(option.strand)}
          onClick={(option) => categoryButtonHandler(option.strand)}
        />

        <ConfirmationSection
          isFormValid={isFormValid}
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          duration={duration}
          getFutureDate={getFutureDate}
          submitHandler={submitHandler}
          t={t}
          isLoading={submissionLoading}
        />
      </div>
      <div className="card p-3 bg-light my-3">
        <RiskTabTitles
          title={t("risks.historyTitle")}
          subtitle={t("risks.historySubtitle")}
        />

        <div className="card bg-white p-2">
          <div className="row text-secondary text-xs my-auto mb-2">
            <div className="col-2 text-uppercase">{t("risks.start")}</div>
            <div className="col-2 text-uppercase">{t("risks.end")}</div>

            <div className="col-2 text-uppercase">{t("addGoal.Group")}</div>
            <div className="col-1 text-uppercase">
              x {t("risks.pointsAbbreviation")}
            </div>
            <div className="col-2 text-uppercase">{t("classList.Status")}</div>
            <div className="col-3 text-uppercase">{t("addGoal.Strand")}(s)</div>
          </div>
          {riskPaloozas.map((item, i) => (
            <div key={i} className="row text-xs my-auto mb-2">
              <div className="col-2">
                {formatTimestamp(item.timestamp, i18n.language)}
              </div>
              <div className="col-2">
                {formatTimestamp(item.end_timestamp, i18n.language)}
              </div>

              <div className="col-2">
                {getGroupName(groupings, item.groupings_id)}
              </div>
              <div className="col-1">{item.multiplier}</div>

              <div className="col-2">
                {isPaloozaActive(item.end_timestamp)
                  ? t("progress.In Progress")
                  : t("risks.inactive")}
              </div>
              <div className="col-3 d-flex flex-wrap">
                {riskStrands.map(
                  (risk, i) =>
                    item[risk] && (
                      <p key={i} className="m-0 me-1 text-uppercase">
                        {t(`risks.${risk}`)}
                      </p>
                    )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
