import React, { useState } from "react";
import NavBar from "./Navbar/NavBar.jsx";
import FooterComponent from "./Footer/FooterComponent.jsx";
import "./PageLayoutStyles.css";
import { Outlet, useNavigate } from "react-router";
import { SearchContext } from "../contexts/SearchContext.jsx";
import { Trans, useTranslation } from "react-i18next";

/**
 * Renders the navbar component with a sign in or sign out button depending on whether or not a user is authenticated
 * @param props
 */

export const HomePageLayout = (props) => {
  const [query, setQuery] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <SearchContext.Provider value={{ query, setQuery }}>
        <div className="py-2 bg-pongo-dark d-flex justify-content-end align-items-center px-3">
          <div onClick={() => navigate("/support")}>
            <p className="text-white m-0 text-xs mx-3 cursor-pointer">
              {t("technicalSupport.Request Information")}
            </p>
          </div>
          <div onClick={() => navigate("/support")}>
            <p className="text-white m-0 text-xs cursor-pointer">
              {t("technicalSupport.Technical Support")}
            </p>
          </div>
        </div>
        <NavBar />
        <div className="py-1 bg-pongo-light d-flex justify-content-center align-items-center px-3 navbar">
          <h5 className="m-0 fw-bold my-2">
            <Trans i18nKey="home.usedAcross">
              <span className="text-pongo">5 school boards</span>
            </Trans>
          </h5>
        </div>
        <Outlet />
        <FooterComponent />
      </SearchContext.Provider>
    </>
  );
};
