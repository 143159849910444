const videoData = {
  student: [
    {
      src: "zp9Lq2Bkf7c?si=CiUQ0Kd0-_k9qg04",
      name: "goals",
      titleEn: "Adding Goals",
      image:
        "https://language-profile.azurewebsites.net/static/images/goal1.png",
    },
    {
      src: "Yg9uHtNmvaE?si=3O4J7J6Sld1QBDpH",
      name: "reflect",
      titleEn: "Reflecting on Goals",
      image:
        "https://language-profile.azurewebsites.net/static/images/goal_icons/Writing_goals/Writing2.png",
    },
    {
      src: "WGLATRWOY7g?si=De57r6JcKUk-7vck",
      name: "progress",
      titleEn: "Viewing Progress",
      image:
        "https://language-profile.azurewebsites.net/static/images/goal3.png",
    },
  ],
  teacher: [
    {
      src: "q6sCJbXxOhg?si=m85kx1MgKgeJTtgY",
      name: "addingStudents",
      titleEn: "Adding Students",
      image: "https://language-profile.azurewebsites.net/static/images/add.png",
    },
    {
      src: "D4_r_FAe-Fg?si=993ycAw0CbaHtys6",
      name: "managingPosts",
      titleEn: "Managing Posts",
      image:
        "https://language-profile.azurewebsites.net/static/images/goal_icons/Writing_goals/Writing1.png",
    },

    {
      src: "JU0tcVRjXnw?si=2jQw4Aiq5Bnt_K4k",
      name: "progress",
      titleEn: "Viewing and Editing Data",
      image:
        "https://language-profile.azurewebsites.net/static/images/goal3.png",
    },
    {
      src: "a76kSHNg8UQ?si=P24916_eyUee_G3b",
      name: "ai",
      titleEn: "AI Activity Planner",
      image:
        "https://language-profile.azurewebsites.net/static/images/goal1.png",
    },
    {
      src: "x4lqTfDNvn8?si=ILrHfKgtwVreEWIu",
      name: "riskPortfolio",
      titleEn: "Risk Portfolio",
      image:
        "https://language-profile.azurewebsites.net/static/images/goal2.png",
    },
  ],
};

export default videoData;
