import React, { useEffect, useState } from "react";

const FetchBitmoji = () => {
  const [bitmojiUrl, setBitmojiUrl] = useState(null);

  useEffect(() => {
    const fetchBitmoji = async () => {
      const accessToken = localStorage.getItem("snapchat_access_token");

      if (accessToken) {
        try {
          const response = await fetch(
            "https://auth.snapchat.com/oauth2/api/user.bitmoji.avatar",
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (response.ok) {
            const data = await response.json();
            setBitmojiUrl(data.data.bitmoji.avatar);
          } else {
            console.error("Failed to fetch Bitmoji avatar");
          }
        } catch (error) {
          console.error("Error fetching Bitmoji avatar:", error);
        }
      }
    };

    fetchBitmoji();
  }, []);

  return (
    <div>
      {bitmojiUrl ? (
        <img src={bitmojiUrl} alt="Bitmoji Avatar" />
      ) : (
        <p>Loading Bitmoji...</p>
      )}
    </div>
  );
};

export default FetchBitmoji;
